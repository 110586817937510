@tailwind base;
@tailwind components;
@tailwind utilities;

ul[role='listbox'] {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
